<template>
  <div
    ref="questionListBox"
    v-loading.fullscreen.lock="fullscreenLoading"
    class="paperPreview"
  >
    <div class="page-title">
      <WTGoBack />
      <span class="title">继续选题</span>
    </div>
    <div class="page-content">
      <div class="fun-btns">
        <div class="save-paper btn" @click="paperSave()">
          <img src="@/static/homework/icon_save.png" alt="" />
          保存试卷
        </div>
        <div class="download-paper btn" @click="downFile()">
          <img src="@/static/homework/icon_down.png" alt="" />
          下载试卷
        </div>

        <div class="analy-paper btn" @click="analyseExamPaperPlan">
          <img src="@/static/homework/icon_ans.png" alt="" />
          试卷分析
        </div>
        <div
          v-if="getSchoolInfo()"
          class="assign-exercise btn"
          @click="paperExercise()"
        >
          <img src="@/static/homework/icon_sheet.png" alt="" />
          生成作业卡
        </div>
      </div>
      <div class="paper-title">
        <div class="score" @click="setPaperScore()">
          <span>总分：{{ paperScore }}分</span>
          <i class="el-icon-edit"></i>
        </div>
        <div class="title">
          <el-input v-model="paperName"></el-input>
          <!-- <div class="remove-question">
            <i class="el-icon-remove"></i>
          </div> -->
        </div>
      </div>
      <div class="questions">
        <div
          v-for="(item, pointer) in paperQuestions"
          :key="pointer"
          class="question-list"
        >
          <div class="list-type">
            <div class="label">
              <span> {{ getChienseNum(pointer + 1) }}、 {{ item.title }}</span>
              <template>
                (
                <el-input v-model="item.content"></el-input>
                <span>)</span>
              </template>
            </div>
            <div class="edit" @click="editQuestionScore(item)">
              <i class="el-icon-edit"></i>修改分值
            </div>
          </div>
          <!--           @mouseleave="resetProcessingType()"          -->
          <!-- 拖拽 -->

          <SlickList
            v-model="item.questionList"
            :lock-to-container-edges="true"
            :hide-sortable-ghost="false"
            :lock-axis="'y'"
            :distance="0.5"
            @input="getChangeLists"
          >
            <SlickItem
              v-for="(val, index) in item.questionList"
              :key="index"
              :index="index"
              class="paper-list-content"
            >
              <div>
                <div class="remove-question" @click="removeQuestion(val)">
                  <i class="el-icon-remove"></i>
                </div>
                <!-- <div > -->
                <!-- 拖动排序 -->
                <img
                  class="list-drag"
                  src="@/assets/imgs/resource/paperdrag.svg"
                  alt=""
                />
                <!-- </div> -->
                <div class="list-item">
                  <!-- <div class="num">{{ getQuesNum(pointer, index) }}.</div> -->
                  <div class="content" v-html="val.question"></div>
                </div>
                <div class="list-btns">
                  <div class="info">
                    <div class="difficulty">
                      <span v-if="val.difficulty <= 1">{{
                        "试题难度：" +
                        getLabelByValue(val.difficulty, difficultyListCopy)
                      }}</span>
                    </div>
                    <div
                      v-if="!dataIsEmpty(val.analyExamDate)"
                      class="difficulty"
                    >
                      <span>日期：{{ val.analyExamDate }}</span>
                    </div>
                    <div
                      v-if="!dataIsEmpty(val.analyScoreRate)"
                      class="difficulty"
                    >
                      <span>得分率：{{ val.analyScoreRate | setNumber }}</span>
                    </div>
                    <div
                      v-if="!dataIsEmpty(val.analyKnowledgeName)"
                      class="difficulty"
                    >
                      <span>知识点：{{ val.analyKnowledgeName }}</span>
                    </div>
                  </div>
                  <div class="btns">
                    <el-button
                      class="btn-item btn-item-text"
                      type="text"
                      :class="{ click: val.processingType == 1 }"
                      @click.stop="questionAnalysis(item, val)"
                    >
                      解析
                    </el-button>
                    <el-button
                      class="btn-item btn-item-text"
                      type="text"
                      :class="{ click: val.processingType == 2 }"
                      @click.stop="questionTestSituation(item, val)"
                    >
                      考情
                    </el-button>
                    <el-button
                      type="text"
                      class="ht"
                      :class="{ click: val.processingType == 3 }"
                      @click.stop="questionChange(val)"
                    >
                      换题<i class="el-icon-refresh"></i>
                    </el-button>
                  </div>
                </div>
                <div v-if="val.processingType == 1" class="list-analy">
                  <div class="analy-title">答案解析</div>
                  <div v-if="val.hasAnalysis" class="analy-answer">
                    <span>
                      答案：<span v-html="val.questionAnaly.answer"></span>
                    </span>
                  </div>
                  <div v-if="!val.hasAnalysis" class="analy-answer">
                    <div class="analy-tip">
                      当前题干没有答案解析，将展示同类题及同类题的答案解析。
                    </div>
                    <div
                      v-loading="changeQuesLoading"
                      class="change-btn"
                      @click="
                        changeQuesSimilar(
                          val.questionId,
                          val.questionAnaly.similarQuesId
                        )
                      "
                    >
                      替换成同类题
                    </div>
                  </div>
                  <div class="analy-content">
                    <div v-if="!val.hasAnalysis" class="analy-item">
                      <!-- <div
                  v-if="val.questionAnaly.newQuestion && !val.hasAnalysis"
                  class="analy-item"
                > -->
                      <div>【同类题】</div>
                      <div v-html="val.questionAnaly.newQuestion"></div>
                    </div>
                    <div v-if="val.questionAnaly.analysis" class="analy-item">
                      <div>【解答】</div>
                      <div v-html="val.questionAnaly.analysis"></div>
                    </div>
                    <div v-if="val.questionAnaly.analyse" class="analy-item">
                      <div>【分析】</div>
                      <div v-html="val.questionAnaly.analyse"></div>
                    </div>
                    <div v-if="val.questionAnaly.ability" class="analy-item">
                      <div>【点评】</div>
                      <div v-html="val.questionAnaly.ability"></div>
                    </div>
                  </div>
                </div>
                <div v-if="val.processingType == 2" class="list-kaoqing">
                  <template
                    v-if="
                      val.situation &&
                      val.situation.anyExamUseQuestion.length == 0
                    "
                  >
                    数据尚未同步
                  </template>
                  <template v-else>
                    <div class="situation-title">
                      考情
                      <span class="fc">
                        【共{{ val.situationExamLen }}场考试/练习采用此题】
                      </span>
                    </div>
                    <div class="paper-list">
                      <div
                        v-for="(m, n) in val.situation.anyExamUseQuestion"
                        :key="n"
                        class="situation-item"
                      >
                        <div class="left">
                          <div class="name">{{ m.examName }}</div>
                          <div class="label">
                            <div v-if="m.examDate" class="label-item">
                              {{ m.examDate }}
                            </div>
                            <div v-if="m.countReplay" class="label-item">
                              {{ m.countReplay }}
                            </div>
                          </div>
                        </div>
                        <div class="right">
                          平均得分率 {{ m.scoreRate | setNumber }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <!-- </div> -->
              </div>
            </SlickItem>
          </SlickList>
        </div>
      </div>
    </div>
    <div class="del-content">
      <div class="content-divide">
        <div>已删除的试题：</div>
        <div class="dashed"></div>
      </div>
      <div v-if="deletedQuestions.length > 0" class="del-questions">
        <div
          v-for="(item, pointer) in deletedQuestions"
          :key="pointer"
          class="question-list"
        >
          <div class="paper-list-content" @mouseleave="resetProcessingType()">
            <div class="list-drag"></div>
            <div class="list-item">
              <!-- <div class="num">{{ pointer + 1 }}.</div> -->
              <div class="content" v-html="item.question"></div>
            </div>
            <div class="list-btns">
              <div class="info">
                <div class="difficulty">
                  <span v-if="item.difficulty <= 1">
                    试题难度：{{
                      getLabelByValue(item.difficulty, difficultyListCopy)
                    }}
                  </span>
                </div>
                <!-- <span>{{
                  "TEST==/" + dataIsEmpty(item.analyExamDate) + "/===="
                }}</span> -->
                <div v-if="!dataIsEmpty(item.analyExamDate)" class="difficulty">
                  <!-- <span>{{ dataIsEmpty(item.analyExamDate) + "====" }}</span> -->
                  <span>日期：{{ item.analyExamDate }}</span>
                </div>
                <!-- <div v-if="paperType > 1" class="difficulty"> -->
                <div
                  v-if="!dataIsEmpty(item.analyScoreRate)"
                  class="difficulty"
                >
                  <span>得分率：{{ item.analyScoreRate | setNumber }}</span>
                </div>
                <div
                  v-if="!dataIsEmpty(item.analyKnowledgeName)"
                  class="difficulty"
                >
                  <span>知识点：{{ item.analyKnowledgeName }}</span>
                </div>
              </div>
              <div class="btns">
                <div
                  class="btn-item"
                  :class="{ click: item.processingType == 1 }"
                  @click="questionDelAnalysis(item)"
                >
                  解析
                </div>
                <div class="btn-item" @click="addQuestion(item)">加入试卷</div>
              </div>
            </div>
            <div v-if="item.processingType == 1" class="list-analy">
              <div class="analy-title">答案解析</div>
              <div v-if="item.hasAnalysis" class="analy-answer">
                <span>
                  答案：<span v-html="item.questionAnaly.answer"></span>
                </span>
              </div>
              <div v-if="!item.hasAnalysis" class="analy-answer">
                <div class="analy-tip">
                  当前题干没有答案解析，将展示同类题及同类题的答案解析。
                </div>
                <div
                  v-loading="changeQuesLoading"
                  class="change-btn"
                  @click="
                    changeQuesSimilar(
                      item.questionId,
                      item.questionAnaly.similarQuesId
                    )
                  "
                >
                  替换成同类题
                </div>
              </div>
              <div class="analy-content">
                <div
                  v-if="item.questionAnaly.newQuestion && !item.hasAnalysis"
                  class="analy-item"
                >
                  <div>【同类题】</div>
                  <div v-html="item.questionAnaly.newQuestion"></div>
                </div>
                <div v-if="item.questionAnaly.analysis" class="analy-item">
                  <div>【解答】</div>
                  <div v-html="item.questionAnaly.analysis"></div>
                </div>
                <div v-if="item.questionAnaly.analyse" class="analy-item">
                  <div>【分析】</div>
                  <div v-html="item.questionAnaly.analyse"></div>
                </div>
                <div v-if="item.questionAnaly.ability" class="analy-item">
                  <div>【点评】</div>
                  <div v-html="item.questionAnaly.ability"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改试题分值 -->
    <el-dialog
      class="dialog-edit-ques-score"
      title="修改分值"
      :visible.sync="editQuestionScoreDialogVisible"
      width="816px"
      :close-on-click-modal="false"
      @close="closeEditQuestionScoreDialog"
    >
      <div class="dialog-content">
        <el-form :model="editQuesScoreForm" label-width="60px">
          <el-form-item label="标题：">
            <el-input
              v-model="editQuesScoreForm.title"
              @change="changeTitleAndContent()"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述：">
            <el-input
              v-model="editQuesScoreForm.content"
              @change="changeTitleAndContent()"
            ></el-input>
          </el-form-item>
          <el-form-item label="总分：">
            <el-input v-model="editQuesScoreForm.allScore"></el-input>
            <span>分</span>
            <div class="average" @click="averageQuesScore()">平均分配</div>
          </el-form-item>
          <el-form-item label="">
            <div class="content-item">
              <div
                v-for="(item, pointer) in editQuesScoreForm.questionList"
                :key="pointer"
                class="item-detail"
              >
                <span class="serial">{{ pointer + 1 }}.</span>
                <el-input
                  v-model="item.score"
                  oninput="this.value = this.value.replace(/[^\d.]/g,'');"
                  @input="resetScoreCount()"
                  @change="resetScoreCount()"
                ></el-input>
                <span>分</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn" @click="saveEditQuestionScoreDialog">保存修改</div>
      </span>
    </el-dialog>
    <!-- 修改试卷分值 -->
    <el-dialog
      class="dialog-edit-paper-score"
      title="修改分值"
      :visible.sync="editPaperScoreDialogVisible"
      width="816px"
      :close-on-click-modal="false"
      @close="closeEditPaperScoreDialog"
    >
      <div class="dialog-content">
        <div class="tab">
          <div
            class="tab-item"
            :class="{ active: editScoreTab == 1 }"
            @click="changeEditScoreTab(1)"
          >
            按题型改分
          </div>
          <div
            class="tab-item"
            :class="{ active: editScoreTab == 2 }"
            @click="changeEditScoreTab(2)"
          >
            按题号改分
          </div>
        </div>
        <div class="content">
          <div v-if="editScoreTab == 1" class="ques-type">
            <div class="type-list">
              <div
                v-for="(item, pointer) in paperQuestions"
                :key="pointer"
                class="type-item"
                :class="{ active: quesTypeIndex == pointer }"
                @click="changeQuesTypeIndex(pointer)"
              >
                {{ item.title }}
              </div>
            </div>
            <el-form :model="editQuesScoreForm" label-width="60px">
              <div
                v-for="(item, pointer) in paperQuestions"
                :key="pointer"
                class="type-item"
              >
                <div v-if="pointer == quesTypeIndex" class="edit-score-type">
                  <div v-if="item.questionList.length < 1" class="no-ques">
                    该题型下暂无相关试题
                  </div>
                  <div v-if="item.questionList.length > 0" class="all-score">
                    <div class="score">
                      <span>总分：</span>
                      <el-input
                        v-model="item.score"
                        oninput="value=value.replace(/[^\d]/g,'')"
                      >
                        <template slot="append">分</template>
                      </el-input>
                    </div>
                    <div
                      class="type-average"
                      @click="averagePaperScoreByType(item)"
                    >
                      平均分配
                    </div>
                  </div>
                  <div v-if="item.questionList.length > 0" class="ques-list">
                    <div
                      v-for="(val, index) in item.questionList"
                      :key="index"
                      class="score-item"
                    >
                      <span>{{ index + 1 }}.</span>
                      <el-input
                        v-model="val.score"
                        oninput="this.value = this.value.replace(/[^\d.]/g,'');"
                        @input="resetPaperScoreCountByType()"
                        @change="resetPaperScoreCountByType()"
                      >
                        <template slot="append">分</template>
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
          </div>
          <div v-if="editScoreTab == 2" class="ques-num">
            <div class="score">
              <span>总分：</span>
              <el-input
                v-model="paperNumScoreData.score"
                :readonly="true"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <template slot="append">分</template>
              </el-input>
            </div>
            <div class="score-list">
              <div
                v-for="(item, pointer) in paperNumScoreData.quesList"
                :key="pointer"
                class="type-item"
              >
                <span>{{ pointer + 1 }}.</span>
                <el-input
                  v-model="item.score"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @change="resetPaperScoreCountByNum()"
                >
                  <template slot="append">分</template>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn" @click="closeEditPaperScoreDialog">取 消</div>
        <div class="btn" @click="saveEditPaperScoreDialog">确 定</div>
      </span>
    </el-dialog>
    <!-- 换题 -->
    <checkQuestion
      :key="key"
      :index-obj="indexObj"
      @onLoadList="onLoadList"
    ></checkQuestion>
    <!--试卷分析弹窗-->
    <ExamPaperAnalysisForm
      v-if="analysisDialogVisible"
      ref="ExamPaperAnalysisForm"
      :key="analyKey"
    ></ExamPaperAnalysisForm>
    <!--下载变式题设置弹窗-->
    <el-dialog
      title="下载设置"
      :visible.sync="dlOptSettingsDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="550px"
    >
      <el-form label-width="100px">
        <el-form-item label="附带答案">
          <el-checkbox
            v-model="addAnswer"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </el-form-item>
        <el-form-item v-if="addAnswer === 1" label="答案位置">
          <el-radio v-model="answerPosition" :label="1">每道试题后面</el-radio>
          <el-radio v-model="answerPosition" :label="2">最后</el-radio>
        </el-form-item>
        <el-form-item label="文档格式">
          <el-radio v-model="fileType" :label="1">Word 文档</el-radio>
          <el-radio v-model="fileType" :label="2">Word 97-2003 文档</el-radio>
          <el-radio v-model="fileType" :label="3">PDF 文档</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="cancelButton"
          @click="dlOptSettingsDialogVisible = false"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="download()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import WTGoBack from "@/components/GoBack.vue";
import {
  examPaperPlan,
  changeQuestionStatus,
  planPaperSave,
  changeQuestionScore,
  changeQuestionBaseInfo,
  downloadExamPaperPlan,
  changePriority,
} from "@/core/api/resource/paperList";
import { difficultyListCopy } from "@/core/util/constdata";
import { answer, situation } from "@/core/api/resource/knowledgePointsPaper";
import { homeworksheet } from "@/core/api/homework/sheet";
import checkQuestion from "@/views/resource/breakdownPaper/components/checkQuestion";
import ExamPaperAnalysisForm from "@/views/resource/form/ExamPaperAnalysisForm";
import { formatDate } from "@/core/util/date";
import { getreplacequestions } from "@/core/api/resource/breakdownPaper";
import { SlickList, SlickItem } from "vue-slicksort";
import { getStore } from "@/core/util/store";
import { capitalNumberFn } from "@/core/util/util";
export default {
  name: "PaperPreview",
  components: {
    WTGoBack,
    checkQuestion,
    ExamPaperAnalysisForm,
    SlickItem,
    SlickList,
  },
  filters: {
    setNumber(val) {
      if (!val) {
        return "0.00 %";
      }
      let number = (val * 100).toFixed(2);
      return number + " %";
    },
  },
  data() {
    return {
      planId: this.$route.query.id,
      paperInfo: [], //试卷信息
      paperName: "",
      paperType: "", //组卷类型(2.3.4:学情组卷)：0-手工组卷 1-细目表组卷 2-错题训练 3-错题拓展 4-薄弱项训练
      paperScore: 0,
      paperQuestions: [],
      deletedQuestions: [],
      processingType: 0, //1-解析 2-考情 3-换题
      questionAnaly: {
        answer: "",
        analyse: "",
        analysis: "",
        ability: "",
        examPaperId: "",
        newQuestion: "",
      }, //解析
      // cnNums: capitalNumberFn(),
      difficultyListCopy: difficultyListCopy,
      editQuestionScoreDialogVisible: false,
      editQuesScoreForm: {},
      currentEditQuestions: [],
      fullscreenLoading: false,
      changeQuesLoading: false, //同类题替换
      //编辑总分
      editPaperScoreDialogVisible: false,
      editScoreTab: 1,
      quesTypeIndex: 0,
      paperTypeScoreData: [],
      paperNumScoreData: [],
      //下载
      showPaperDownloadDialog: false,
      //换题
      indexObj: {},
      key: 0,
      analyKey: 0,
      //下载组卷
      analysisDialogVisible: false, // 试卷分析弹窗
      dlOptSettingsDialogVisible: false, // 下载变式题设置弹窗
      addAnswer: 1, // 下载变式题设置-附带答案 0-不附带 1-附带
      answerPosition: 2, // 下载变式题设置-答案位置 1-每道试题后面 2-最后
      fileType: 1, // 下载文档格式 1-Word 文档 2-Word 97-2003 文档 3-PDF 文档
      application: [],
      subjectId: "",
    };
  },
  mounted() {
    window.MathJax.typesetPromise();
  },
  updated() {
    window.MathJax.startup.defaultReady();
  },
  created() {
    this.getExamPaperInfo();
  },
  methods: {
    getSchoolInfo() {
      let { application } = getStore({
        name: "schoolInfo",
      });
      // 非9大科目  加道德与法治不得生成答题卡
      let subjectArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11];
      if (subjectArr.indexOf(this.subjectId) == -1) {
        return false;
      }
      if (!application) {
        application = "";
      }
      this.application = application.split(",");
      if (application.indexOf("221") != -1) {
        return true;
      } else {
        return false;
      }
    },
    downFile() {
      // if (this.paperType == 101) {
      //   let data = {
      //     id: this.planId,
      //   };
      //   this.downloadExamPaperPlan(data);
      //   return;
      // }
      this.dlOptSettingsDialogVisible = true;
    },
    download() {
      let data = {
        id: this.planId,
        addAnswer: this.addAnswer,
        answerPosition: this.answerPosition,
        fileType: this.fileType,
      };
      this.downloadExamPaperPlan(data);
    },
    getChangeLists() {
      // 拖拽完成后返回被打乱后的顺序
      // console.log(vals);
      let arr = [];
      this.paperQuestions.forEach((item) => {
        item.questionList.forEach((index) => {
          arr.push(index.id);
        });
      });
      let data = {
        planId: this.planId,
        questionIds: arr.join(","),
      };
      changePriority(data)
        .then(() => {
          this.getExamPaperInfo();
        })
        .catch(() => {
          this.getExamPaperInfo();
        });
      // let vue
    },
    // 当放置被拖元素时
    handleDrop(e, item, index) {
      e.dataTransfer.dropEffect = "move";
      if (item.type != this.dragging.type) {
        return;
      }
      if (item === this.dragging) {
        return;
      }
      const newItems = [...this.paperQuestions];
      const from = newItems[index].questionList.indexOf(this.dragging);
      const to = newItems[index].questionList.indexOf(item);
      newItems[index].questionList.splice(from, 1);
      newItems[index].questionList.splice(to, 0, this.dragging);
      let arr = [];
      newItems.forEach((index) => {
        index.questionList.forEach((item) => {
          arr.push(item.id);
        });
      });
      let data = {
        planId: this.planId,
        questionIds: arr.join(","),
      };
      changePriority(data).then(() => {
        this.paperQuestions = newItems;
      });

      // this.divList = newItems;
    },
    // 拖拽方法 end
    dataIsEmpty(field) {
      if (typeof field == "undefined" || field == null || field == "") {
        return true;
      } else {
        return false;
      }
    },
    filterNullData(field) {
      if (this.dataIsEmpty(field)) {
        return "--";
      } else {
        return field;
      }
    },
    //
    getQuesNum(type_index, ques_index) {
      let typeLen = 0;
      let quesNum = 0;
      this.paperQuestions.forEach((item, key) => {
        if (key < type_index) {
          typeLen += item.questionList.length;
        }
        quesNum = typeLen + ques_index;
      });
      return quesNum + 1;
    },
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },
    //换题
    showCheck(v) {
      this.indexObj = v;
      this.key = new Date().getTime();
    },
    //子组件调用  重新更新父组件
    onLoadList() {
      this.getExamPaperInfo();
    },
    //
    getExamPaperInfo() {
      examPaperPlan(this.planId).then((res) => {
        this.paperInfo = res.data.data;
        this.paperName = res.data.data.name;
        this.paperType = res.data.data.type;
        this.subjectId = res.data.data.subjectId;
        if (this.dataIsEmpty(this.paperName)) {
          this.paperName = this.getCurrentDate() + " 选题组卷";
        }
        this.paperScore = 0;
        this.paperQuestions = res.data.data.questionTypeList;
        this.deletedQuestions = [];
        let array = JSON.parse(JSON.stringify(this.paperQuestions));
        let delArr = [];
        array.map((item) => {
          item.score = 0;
          let arr = JSON.parse(JSON.stringify(item.questionList));
          arr.map((val) => {
            val.processingType = 0;
            val.questionAnaly = {}; //解析
            val.situation = {}; //考情
            if (val.delFlag == 1) {
              //已删除试题
              val.title = item.title;
              this.deletedQuestions.push(val);
              delArr.push(val.questionId);
            } else {
              this.paperScore += val.score;
              item.score += Number(val.score);
            }
          });
        });
        //第二次  过滤出已经删除的
        array.map((index) => {
          index.questionList = index.questionList.filter(
            (i) => delArr.indexOf(i.questionId) == -1
          );
        });
        this.paperQuestions = array;
        this.oldQuestionList = JSON.parse(JSON.stringify(this.paperQuestions));
        // 插入题号
        this.insertQuestionNum(false, this.paperQuestions);
        this.insertQuestionNum(true, this.deletedQuestions);
      });
    },
    // 插入题号
    insertQuestionNum(isDel, quesList) {
      if (!isDel) {
        quesList.forEach((item, key) => {
          if (item.questionList.length > 0) {
            this.getWholeQuestion(isDel, item.questionList, key);
          }
        });
        this.paperQuestions = JSON.parse(JSON.stringify(this.paperQuestions));
      } else {
        this.getWholeQuestion(isDel, quesList);
      }
    },
    getWholeQuestion(isDel, quesList, key) {
      quesList.forEach((val, index) => {
        let num = index + 1; //题号
        if (!isDel) {
          num = this.getQuesNum(key, index);
        }
        let numTag = "<span style='margin-right:10px'>" + num + ".</span>";
        if (val.question.startsWith("<p") || val.question.startsWith("<div")) {
          let numIndex = val.question.indexOf(">");
          val.question =
            val.question.substr(0, numIndex + 1) +
            numTag +
            val.question.substr(numIndex + 1);
        } else {
          val.question = numTag + val.question;
        }
      });
    },

    getChienseNum(num) {
      return capitalNumberFn(num);
    },
    paperSave() {
      planPaperSave({
        id: this.planId,
        name: this.paperName,
      }).then(() => {
        this.$message.success(
          "试卷保存成功，可去“我的资源”→“我的组卷”中查看记录。"
        );
        this.getExamPaperInfo();
      });
    },
    getCurrentDate() {
      return formatDate(new Date(), "yyyyMMdd");
    },
    analyseExamPaperPlan() {
      this.analyKey = new Date().getTime();
      this.analysisDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ExamPaperAnalysisForm.initPlan(this.planId);
      });
    },
    downloadExamPaperPlan(data) {
      this.paperSave();
      this.$message.info("文档生成中，请耐心等待！");
      this.dlOptSettingsDialogVisible = false;
      downloadExamPaperPlan(data).then((response) => {
        if (response.code === 0 || response.data.code === 0) {
          this.downloadRequest(response.data.data.url, response.data.data.name);
        } else {
          this.$message.error(response.msg);
        }
      });
    },
    downloadRequest(url, name) {
      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function () {
        let url = window.URL.createObjectURL(request.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      request.send();
    },
    paperExercise() {
      if (this.btnLoading) return;
      this.btnLoading = true;
      homeworksheet({
        planId: this.$route.query.id,
      })
        .then((res) => {
          this.btnLoading = false;
          const { href } = this.$router.resolve({
            name: "/homework/makeSheet",
            query: {
              uuid: res.data.data,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //总分编辑
    setPaperScore() {
      this.editPaperScoreDialogVisible = true;
      this.paperTypeScoreData = this.paperQuestions;
      this.editScoreTab = 1;
      this.quesTypeIndex = 0;
    },
    changeEditScoreTab(index) {
      this.editScoreTab = index;
      this.paperNumScoreData = [];
      let quesList = [];
      if (this.editScoreTab == 2) {
        this.paperQuestions.forEach((item) => {
          if (item.questionList && item.questionList.length > 0) {
            item.questionList.forEach((val) => {
              val.title = item.title;
              quesList.push(val);
            });
          }
        });
        this.paperNumScoreData = {
          score: this.paperScore,
          quesList: quesList,
        };
        this.paperNumScoreData = JSON.parse(
          JSON.stringify(this.paperNumScoreData)
        );
      }
    },
    changeQuesTypeIndex(index) {
      this.quesTypeIndex = index;
    },
    averagePaperScoreByType(ques) {
      this.$confirm("平均分配会覆盖所有试题已有分数，是否确认修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let count = ques.score;
          let len = ques.questionList.length;
          let _score = Math.floor((count / len) * 10) / 10;
          this.paperQuestions[this.quesTypeIndex].questionList.forEach(
            (item, key) => {
              item.score = _score;
              if (_score * len != count && key == len - 1) {
                let temp = _score * (len - 1);
                item.score = (count - temp).toFixed(1);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消平均分配",
          });
        });
    },
    resetPaperScoreCountByType() {
      let count = 0;
      this.paperQuestions[this.quesTypeIndex].questionList.forEach((item) => {
        count += Number(item.score);
      });
      this.paperQuestions[this.quesTypeIndex].score = count.toFixed(1);
      this.paperQuestions = JSON.parse(JSON.stringify(this.paperQuestions));
    },
    resetPaperScoreCountByNum() {
      let count = 0;
      this.paperNumScoreData.quesList.forEach((item) => {
        count += Number(item.score);
      });
      this.paperNumScoreData.score = count;
      this.paperNumScoreData = JSON.parse(
        JSON.stringify(this.paperNumScoreData)
      );
    },
    closeEditPaperScoreDialog() {
      this.editPaperScoreDialogVisible = false;
    },
    saveEditPaperScoreDialog() {
      let quesArr = [];
      let checkData = true;
      if (this.editScoreTab == 1) {
        //按题型
        this.paperQuestions.map((item) => {
          let score = 0;
          item.questionList.map((val) => {
            score += Number(val.score);
            let obj = {
              delFlag: val.delFlag,
              id: val.id,
              originalId: val.originalId,
              planId: this.planId,
              priority: val.priority,
              questionId: val.questionId,
              score: val.score,
            };
            quesArr.push(obj);
          });
          if (score != item.score) {
            this.$message.error("请确认各题型总分和小题分值之和一致");
            checkData = false;
            return;
          }
        });
      } else if (this.editScoreTab == 2) {
        //按题号
        this.paperNumScoreData.quesList.forEach((item) => {
          let obj = {
            delFlag: item.delFlag,
            id: item.id,
            originalId: item.originalId,
            planId: this.planId,
            priority: item.priority,
            questionId: item.questionId,
            score: item.score,
          };
          quesArr.push(obj);
        });
      }
      if (checkData) {
        changeQuestionScore(quesArr).then(() => {
          this.closeEditPaperScoreDialog();
          this.getExamPaperInfo();
        });
      }
    },
    //
    editQuestionScore(ques) {
      this.currentEditQuestions = ques;
      let scoreCount = 0;
      ques.questionList.forEach((item) => {
        scoreCount += item.score;
      });
      this.editQuesScoreForm = {
        title: ques.title,
        content: ques.content,
        allScore: scoreCount,
        questionList: ques.questionList,
      };
      this.editQuestionScoreDialogVisible = true;
    },
    averageQuesScore() {
      this.$confirm("平均分配会覆盖所有试题已有分数，是否确认修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let count = this.editQuesScoreForm.allScore;
          let len = this.editQuesScoreForm.questionList.length;
          let _score = Math.floor((count / len) * 10) / 10;
          this.editQuesScoreForm.questionList.forEach((item, key) => {
            item.score = Number(_score);
            if (_score * len != count && key == len - 1) {
              let temp = _score * (len - 1);
              item.score = (count - temp).toFixed(1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消平均分配",
          });
        });
    },
    resetScoreCount() {
      let count = 0;
      this.editQuesScoreForm.questionList.forEach((item) => {
        count += Number(item.score);
      });
      this.editQuesScoreForm.allScore = count.toFixed(1);
    },
    closeEditQuestionScoreDialog() {
      this.editQuestionScoreDialogVisible = false;
    },
    saveEditQuestionScoreDialog() {
      //questions
      let quesArr = [];
      let score = 0;
      this.editQuesScoreForm.questionList.forEach((item) => {
        let obj = {
          delFlag: item.delFlag,
          id: item.id,
          originalId: item.originalId,
          planId: this.planId,
          priority: item.priority,
          questionId: item.questionId,
          score: item.score,
        };
        score += Number(item.score);
        quesArr.push(obj);
      });
      if (score != this.editQuesScoreForm.allScore) {
        this.$message.error("总分和小题分值之和不一致");
      } else {
        changeQuestionScore(quesArr)
          .then(() => {
            this.editQuestionScoreDialogVisible = false;
            this.getExamPaperInfo();
          })
          .catch((res) => {
            this.$message.error(res.data.data.msg);
          });
      }
    },
    changeTitleAndContent() {
      this.saveBaseInfo();
    },
    saveBaseInfo() {
      let obj = {
        content: this.editQuesScoreForm.content,
        planId: this.planId,
        title: this.editQuesScoreForm.title,
        type: this.editQuesScoreForm.questionList[0].type,
      };
      changeQuestionBaseInfo(obj).then(() => {
        this.getExamPaperInfo();
      });
    },
    resetProcessingType() {
      // this.paperQuestions.forEach(item => {
      //   if (item.questionList && item.questionList.length > 0) {
      //     item.questionList.forEach(val => {
      //       val.processingType = 0;
      //       val.click = false;
      //     });
      //   }
      // });
    },
    removeQuestion(val) {
      let obj = {
        delFlag: val.delFlag == 0 ? 1 : 0,
        id: val.id,
        originalId: 0,
        planId: this.planId,
        priority: val.priority,
        questionId: val.questionId,
        score: val.score,
      };
      this.addOrDeleteQuestion(obj);
    },
    addOrDeleteQuestion(ajaxObj) {
      changeQuestionStatus(ajaxObj).then(() => {
        this.getExamPaperInfo();
      });
    },
    questionAnalysis(type, ques) {
      if (ques.processingType == 1) {
        ques.processingType = 0;
        ques.click = false;
        this.transcodingBaseInfo();
      } else {
        ques.processingType = 1;
        answer(ques.questionId).then((res) => {
          if (!res.data.data.id) {
            this.$message.warning("未找到同类题和解析");
          }
          ques.hasAnalysis = true;
          let analy = res.data.data;
          let _ques = {
            answer: analy.answer,
            analyse: analy.analyse,
            analysis: analy.analysis,
            ability: analy.ability,
            examPaperId: analy.examPaperId,
          };
          if (res.data.data.id != ques.questionId) {
            ques.hasAnalysis = false;
            _ques.newQuestion = analy.question;
            _ques.similarQuesId = res.data.data.id;
          }
          this.paperQuestions.forEach((item) => {
            if (item.title == type.title) {
              item.questionList.forEach((val) => {
                if (val.questionId == ques.questionId) {
                  val.questionAnaly = _ques;
                }
              });
            }
          });
          this.transcodingBaseInfo();
        });
      }
    },
    questionTestSituation(type, ques) {
      if (ques.processingType == 2) {
        ques.processingType = 0;
        ques.click = false;
        this.transcodingBaseInfo();
      } else {
        ques.processingType = 2;
        situation(ques.questionId).then((res) => {
          let _data = res.data.data;
          ques.hasSituation = false;
          if (_data.anyExamUseQuestion && _data.anyExamUseQuestion.length > 0) {
            ques.hasSituation = true;
            ques.situationExamLen = _data.anyExamUseQuestion.length;
          } else {
            ques.situationExamLen = 0;
          }
          let _situation = {
            anyExamUseQuestion: _data.anyExamUseQuestion,
            countReply: _data.countReply,
            examNum: _data.examNum,
            scoreRate: _data.scoreRate,
          };
          this.paperQuestions.forEach((item) => {
            if (item.title == type.title) {
              item.questionList.forEach((val) => {
                if (val.questionId == ques.questionId) {
                  val.situation = _situation;
                }
              });
            }
          });
          this.transcodingBaseInfo();
        });
      }
    },
    transcodingBaseInfo() {
      this.paperQuestions = JSON.parse(JSON.stringify(this.paperQuestions));
    },
    questionDelAnalysis(ques) {
      ques.processingType = 1;
      answer(ques.questionId).then((res) => {
        if (res.data.data.id) {
          ques.hasAnalysis = true;
          let analy = res.data.data;
          let _ques = {
            answer: analy.answer,
            analyse: analy.analyse,
            analysis: analy.analysis,
            ability: analy.ability,
            examPaperId: analy.examPaperId,
          };
          if (res.data.data.id != ques.questionId) {
            ques.hasAnalysis = false;
            _ques.newQuestion = analy.question;
            _ques.similarQuesId = res.data.data.id;
          }
          this.deletedQuestions.forEach((item) => {
            if (item.questionId == item.questionId) {
              item.questionAnaly = _ques;
            }
          });
          this.transcodingBaseInfo();
        } else {
          this.$message.warning("未找到同类题和解析");
        }
      });
    },
    questionChange(question) {
      this.showCheck(question);
    },
    changeQuesSimilar(ques_id, similar_id) {
      this.changeQuesLoading = true;
      getreplacequestions({
        planId: this.planId,
        examQuestionId: ques_id,
        examQuestionIdReplace: similar_id,
        checkType: 1,
      })
        .then(() => {
          this.changeQuesLoading = false;
          this.$message.success("换题成功");
          this.getExamPaperInfo();
        })
        .catch(() => {
          this.changeQuesLoading = false;
        });
      //添加同类题到试题篮
      // exampaperplanquestion({
      //   planId: this.planId,
      //   questionId: similar_id
      // })
      //   .then(() => {
      //删除原题
      //   this.delOriginalQues(ques_id);
      // })
      // .catch(() => {
      //   this.changeQuesLoading = false;
      // });
    },
    // delOriginalQues(ques_id) {
    //   exampaperplanquestionDel({
    //     examPaperPlanId: this.planId,
    //     questionId: ques_id
    //   })
    //     .then(() => {
    //       this.changeQuesLoading = false;
    //       this.$message.success("换题成功");
    //       this.getExamPaperInfo();
    //     })
    //     .catch(() => {
    //       this.changeQuesLoading = false;
    //     });
    // },
    // 添加到试题篮
    addQuestion(ques) {
      let _priority = 0;
      this.paperQuestions.forEach((item) => {
        if (item.title == ques.title) {
          item.questionList.forEach((val) => {
            _priority = val.priority;
          });
        }
      });
      let obj = {
        delFlag: ques.delFlag == 0 ? 1 : 0,
        id: ques.id,
        originalId: 0,
        planId: this.planId,
        priority: _priority,
        questionId: ques.questionId,
        score: ques.score,
      };
      this.addOrDeleteQuestion(obj);
    },
  },
};
</script>
<style lang="scss">
body > .paper-list-content {
  border: 1px solid #2474ed;
}
.paper-list-content {
  border: 1px solid #ffffff;
  border-radius: 4px;
  position: relative;
  // padding-top: 20px;
  cursor: pointer;
  .remove-question {
    position: absolute;
    top: -1px;
    left: 1086px;
    cursor: pointer;
    visibility: hidden;
    transition: 0.5s;
    .el-icon-remove {
      color: #d81e06;
      font-size: 14px;
    }
  }
  &:active {
    border: 1px solid #2474ed;
  }
  // &:not(:last-child) {
  // margin-bottom: 30px;
  // }
  &:hover {
    border: 1px solid #2474ed;
    .remove-question {
      visibility: visible;
      transition: 0.5s;
    }
    .list-btns {
      display: flex;
    }
    .list-drag {
      opacity: 1;
    }
  }
  .remove-question {
    top: -11px;
    left: 1084px;
  }
  .list-item {
    margin: 0 44px 0px 18px;
    // display: flex;
    .num {
      width: 26px;
      z-index: 999;
    }
    .content {
      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }
  }
  .list-drag {
    width: 20px;
    position: absolute;
    right: 20px;
    top: 10px;
    opacity: 0;
  }
  .list-btns {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: none;
    .info {
      display: flex;
      padding: 0 15px;
      .difficulty {
        padding: 10px 30px;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .btn-item-text {
        padding: 0;
      }
      .btn-item {
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        margin-right: 8px;
        &:not(:last-child) {
          margin-right: 12px;
        }

        &:hover {
          color: #2474ed;
        }
        .el-icon-refresh {
          margin-left: 32px;
        }
      }
      .ht {
        margin-left: 8px;
        background-color: #2474ed;
        color: #ffffff !important;
        padding: 11.5px 16px;
        border-radius: 0;
      }
      .click {
        // background-color: #ffffff;
        color: #2474ed;
        // padding: 10px;
      }
    }
  }
  .list-analy {
    .analy-title {
      color: #2474ed;
      padding: 10px 30px;
    }
    .analy-answer {
      color: rgba(0, 0, 0, 0.5);
      padding: 10px 30px;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .change-btn {
        padding: 6px 16px;
        border: 1px solid #2474ed;
        color: #2474ed;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .analy-content {
      .analy-item {
        padding: 10px 30px;
        border-bottom: 1px dashed #dddddd;
        overflow: hidden;
      }
      .paper-id {
        color: #2474ed;
        padding-top: 10px;
        border-top: 1px solid #dddddd;
      }
    }
  }
  .list-kaoqing {
    padding: 22px 30px 30px;
    color: rgba(0, 0, 0, 0.5);
    .situation-title {
      color: #2474ed;
      margin-bottom: 6px;
      .fc {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .paper-list {
      border-bottom: 1px solid #e0e0e0;
      .situation-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0 16px 20px;
        border: 1px solid #e0e0e0;
        border-bottom: unset;
        .left {
          .name {
            font-size: 16px;
            font-weight: 700;
            color: rgba(0, 0, 0, 1);
          }
          .label {
            .label-item {
              padding: 4px 0px;
              &:not(:last-child) {
                border-right: 1px solid #dddddd;
              }
            }
          }
        }
        .right {
          padding: 0 32px;
          color: rgba(33, 150, 243, 1);
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
}
.paperPreview {
  .page-content {
    .paper-title {
      .el-input__inner {
        border: none;
        height: 70px;
        font-size: 24px;
        font-weight: 700;
        color: #000000;
        text-align: center;
        padding: unset;
      }
    }
    .questions {
      .question-list {
        .list-type {
          .el-input {
            width: fit-content;
            .el-input__inner {
              border: none;
              font-weight: 700;
              color: rgba(0, 0, 0, 0.85);
              padding: unset;
              height: unset;
              line-height: unset;
            }
          }
        }
      }
    }
  }
  // .dialog-edit-ques-score {
  .el-dialog__header {
    border-bottom: 1px solid #e0e0e0;
  }
  .el-dialog__body {
    .dialog-content {
      .el-form-item {
        .el-form-item__label {
          text-align: left;
        }
        label {
          margin-bottom: unset;
        }
        .el-form-item__content {
          display: flex;
          align-items: center;
          .el-input {
            width: 50%;
            margin-right: 10px;
          }
          .el-input__inner {
            height: unset;
            line-height: unset;
          }
        }
        &:last-child {
          background-color: #f8f8f8;
          .el-form-item__content {
            margin-left: 0 !important;
            padding: 20px;
          }
        }
      }
      .el-input.is-active .el-input__inner,
      .el-input__inner:focus {
        border-color: #2474ed;
      }
    }
  }
  // }
  .dialog-edit-paper-score {
    .el-dialog__body {
      padding: 30px 20px 0;
    }
    .el-dialog__footer {
      padding: 10px 20px 40px;
    }
    .el-input {
      width: 50%;
      margin: 0 10px;

      .el-input__inner {
        height: 42px;
        line-height: 42px;
        border-right: none;
      }
      .el-input__inner:focus {
        border-color: #dcdfe6;
      }
      .el-input-group__append {
        background-color: #ffffff;
        border-left: none !important;
        padding: 0 10px;
      }
    }
    // }
    //     }
    //   }
    // }
  }
}
</style>
<style scoped lang="scss">
.paperPreview {
  width: 1220px;
  margin: 0 auto 60px;
  color: rgba(0, 0, 0, 0.85);
  .page-title {
    height: 56px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    align-items: center;
    padding: 0 40px;
    .title {
      padding: 16px 0;
      font-size: 16px;
      text-align: left;
      font-weight: 400;
    }
  }

  .page-content {
    padding: 0 64px;
    .fun-btns {
      display: flex;
      justify-content: flex-end;
      margin: 48px 0 24px;
      .btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 22px;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
          vertical-align: text-bottom;
        }
      }
    }
    .paper-title {
      position: relative;
      .score {
        display: flex;
        align-items: center;
        .el-icon-edit {
          color: #2474ed;
          margin-left: 2px;
          cursor: pointer;
        }
      }
      .title {
        height: 72px;
        font-size: 24px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid #2474ED;
        &:hover {
          border: 1px solid #2474ed;
          .remove-question {
            visibility: visible;
          }
        }
      }
    }
    .questions {
      margin-top: 48px;
      .question-list {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        .list-type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
          margin-bottom: 10px;
          &:hover {
            border: 1px solid #2474ed;
            border-radius: 4px;
            .edit {
              visibility: visible;
            }
          }
          .label {
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 90%;
          }
          .edit {
            color: rgba(0, 0, 0, 0.5);
            visibility: hidden;
            cursor: pointer;
            .el-icon-edit {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .del-content {
    padding: 0 64px;
    margin: 30px 0;
    .content-divide {
      display: flex;
      align-items: center;
      div {
        &:first-child {
          flex-shrink: 0;
        }
      }
      // margin-bottom: 30px;
      .dashed {
        border-bottom: 1px dashed #000000;
        width: 994px;
      }
    }
  }
  .dialog-content {
    .average {
      border: 1px solid #2474ed;
      color: #2474ed;
      border-radius: 4px;
      padding: 0px 10px;
      height: 34px;
      line-height: 34px;
      margin-left: 30px;
      cursor: pointer;
    }
    .content-item {
      .item-detail {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .serial {
          margin-right: 10px;
        }
      }
    }
  }
  .dialog-edit-ques-score {
    .dialog-footer {
      display: flex;
      justify-content: center;
      .btn {
        padding: 10px 16px;
        color: #ffffff;
        background-color: #2474ed;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .dialog-edit-paper-score {
    .dialog-content {
      .tab {
        display: flex;
        margin-bottom: 20px;
        .tab-item {
          padding: 0px 10px;
          cursor: pointer;
          &:not(:last-child) {
            border-right: 1px solid #dddddd;
          }
        }
        .active {
          color: #2474ed;
        }
      }
      .ques-type {
        .type-list {
          display: flex;
          margin-bottom: 20px;
          padding: 0 10px;
          .type-item {
            padding: 10px 16px;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
          .active {
            color: #ffffff;
            background-color: #2474ed;
            border-radius: 4px;
          }
        }
      }
      .ques-num {
        .score {
          display: flex;
          align-items: center;
        }
        .score-list {
          background-color: #f8f8f8;
          padding: 20px;
          margin-top: 20px;
          .type-item {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
      .el-form {
        padding: 0 10px;
        .type-item {
          .edit-score-type {
            .no-ques {
              padding: 20px;
              border: 1px dashed #dddddd;
              margin-bottom: 20px;
              text-align: center;
              font-size: 16px;
            }
            .all-score {
              display: flex;
              align-items: center;
              .score {
                display: flex;
                align-items: center;
              }
              .type-average {
                border: 1px solid #2474ed;
                color: #2474ed;
                border-radius: 4px;
                padding: 0px 10px;
                height: 34px;
                line-height: 34px;
                margin-left: 30px;
                cursor: pointer;
              }
            }
            .ques-list {
              background-color: #f8f8f8;
              padding: 20px;
              margin: 20px 0;
              .score-item {
                display: flex;
                align-items: center;
                &:not(:last-child) {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
      .btn {
        padding: 10px 40px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid #2474ed;
        color: #2474ed;
        &:last-child {
          color: #ffffff;
          background-color: #2474ed;
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
